/* QRScanner.css */
.qr-reader-container {
    overflow: hidden;
    border: 1px solid #ccc;
    margin: 0 auto; /* Center the container */
}

/* Estilos para dispositivos pequeños (celulares) */
@media (max-width: 600px) {
    .qr-reader-container {
        width: 10cm;
        height: 10cm;
    }
}

/* Estilos para dispositivos medianos (tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
    .qr-reader-container {
        width: 15cm;
        height: 15cm;
    }
}

/* Estilos para dispositivos grandes (computadoras) */
@media (min-width: 1025px) {
    .qr-reader-container {
        width: 20cm;
        height: 20cm;
    }
}

